.component {
  font-size: var(--font-size-16);
  color: var(--color-blue-700);
  display: flex;
  justify-content: center;
  padding: 0 var(--size-32);
  position: sticky;

  @media (--viewport-md) {
    padding: 0;
  }

  & > .layout {
    width: 100%;

    @media (--viewport-md) {
      width: auto;
    }
  }
}

.layout {
  background-color: var(--color-white);
  border-radius: var(--border-radius-pill);
}

.componentNavMobile {
  position: relative;
  background-color: var(--color-white);
  border-radius: var(--border-radius-pill);
  padding: var(--size-8) 0;

  @media (--viewport-md) {
    display: none;
  }

  & > .select {
    width: 100%;
  }

  & > .icon {
    position: absolute;
    top: 0;
    right: var(--size-18);
    height: 100%;
  }
}

.componentNavDesktop {
  display: none;
  padding: var(--size-8) var(--size-8);
  position: relative;

  @media (--viewport-md) {
    display: flex;
    justify-content: space-between;
    gap: var(--size-16);
  }

  & > .anchorLink {
    @media (--viewport-md) {
      flex-grow: 1;
      min-width: 150px;
    }

    @media (--viewport-lg) {
      min-width: 285px;
    }
  }
}

.anchorLink {
  text-align: center;
  background-color: var(--color-white);
  border-radius: var(--border-radius-pill);
  padding: var(--size-8) 0;
  transition: background-color var(--duration-200);

  &:hover {
    background-color: var(--color-blue-200);
  }

  &.isActive {
    background-color: var(--color-blue-700);
    color: var(--color-white);
  }
}

.select {
  text-align: center;
}

.icon {
  display: flex;
  align-items: center;
  pointer-events: none;
}
