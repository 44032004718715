.component {
  --translateY: 0;

  border-radius: 100%;
  background-color: var(--color-blue-500);
  height: 55px !important;
  width: 55px !important;
  display: grid;
  place-items: center;
  color: var(--color-white);

  &:hover {
    --translateY: 2px;
  }
}

.icon {
  transform: translateY(var(--translateY));
  transition: transform var(--duration-200) var(--ease-in-out);
}
