.component {
  color: var(--color-blue-700);
  padding-top: var(--size-40);

  @media (--viewport-md) {
    padding-top: var(--size-74);
  }
}

.layout {
  padding-top: var(--size-74);

  & > .backLinkLayout {
    margin-bottom: var(--size-48);
  }

  & > .categoryLayout {
    margin-bottom: var(--size-24);
  }

  & > .headingLayout {
    margin-bottom: var(--size-18);
  }
}
