.component {
  --color-date: var(--color-gray-500);
  --color-icon: var(--color-white);
  --color-subheading: var(--color-gray-400);
  --color-heading: var(--color-black);
  --bg-color-card: var(--color-white);
  --bg-color-icon: var(--color-blue-500);
  --color-category: var(--color-white);
  --bg-color-category: var(--color-blue-700);
  --color-date: var(--color-blue-700);
  --bg-color-date: var(--color-blue-100);

  overflow: hidden;
  border-radius: var(--border-radius-12);
  background-color: var(--bg-color-card);
  scale: var(--card-scale);
  transition: background-color var(--duration-150) linear,
    scale var(--duration-750) var(--ease-out-expo);

  &:hover,
  &:focus-visible {
    --color-date: var(--color-white);
    --color-icon: var(--color-blue-700);
    --color-subheading: var(--color-gray-100);
    --color-heading: var(--color-white);
    --bg-color-card: var(--color-blue-700);
    --bg-color-icon: var(--color-white);
    --color-category: var(--color-white);
    --bg-color-category: var(--color-white--20);
    --color-date: var(--color-white);
    --bg-color-date: var(--color-white--30);
    --card-scale: 1.02;
  }
}

.layout {
  position: relative;
  z-index: 0;
  display: grid;
  gap: var(--size-12);

  @media (--viewport-lg) {
    grid-template-columns: 3fr 1fr;
  }
}

.content {
  padding: var(--size-24) var(--size-16);

  @media (--viewport-md) {
    padding: var(--size-32) var(--size-24);
  }

  & > .header {
    margin-bottom: var(--size-24);
  }
}

.header {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-8);

  &.relativeToParent {
    position: static;
  }

  & > .meta {
    margin-bottom: var(--size-10);
  }
}

.meta {
  display: flex;
  align-items: center;
  gap: var(--size-10);
}

.category {
  padding: var(--size-8) var(--size-12);
  font-size: var(--font-size-14);
  background-color: var(--bg-color-category);
  color: var(--color-category);
  border-radius: var(--border-radius-pill);
  line-height: 1;
  transition: color var(--duration-150) linear,
    background-color var(--duration-150) linear;
}

.date {
  padding: var(--size-8) var(--size-12);
  font-size: var(--font-size-14);
  background-color: var(--bg-color-date);
  color: var(--color-date);
  border-radius: var(--border-radius-pill);
  line-height: 1;
  transition: color var(--duration-150) linear,
    background-color var(--duration-150) linear;
}

.heading {
  position: relative;
  z-index: 0;
  color: var(--color-heading);
  font-family: var(--font-family-base);
  line-height: var(--line-height-sm);
  font-size: var(--font-size-18-24);
  transition: color var(--duration-150) linear;

  &.relativeToParent {
    position: static;
  }
}

.subheading {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-light);
  font-family: var(--font-family-base);
  color: var(--color-subheading);
  transition: color var(--duration-150) linear;
}

.imageWithIcon {
  position: relative;
  display: grid;
  grid-template-rows: subgrid;

  & > .icon {
    position: absolute;
    bottom: var(--size-24);
    right: var(--size-24);
    width: var(--size-32);
    height: var(--size-32);
  }
}

.icon {
  display: none;

  @media (--viewport-lg) {
    display: grid;
    place-items: center;
    border-radius: 50%;
    background-color: var(--bg-color-icon);
    color: var(--color-icon);
    pointer-events: none;
  }

  & > * {
    width: var(--size-24);
    height: var(--size-24);
  }
}

.link {
  position: relative;

  &.relativeToParent {
    position: static;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.imageContainer {
  aspect-ratio: 1;
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}
