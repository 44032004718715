.component {
  font-family: var(--font-family-heading);
  color: var(--color-blue-600);
  font-size: var(--font-size-40-240);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-xl);

  @media (--viewport-md) {
    text-align: center;
  }
}
