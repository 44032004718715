.page {
  background-color: var(--color-blue-300);
  padding-top: var(--size-112);
  position: relative;
  z-index: 0;

  @media (--viewport-md) {
    padding-top: var(--size-148);
  }

  & > .highlightedArticles {
    margin-bottom: var(--size-56);
  }

  & > .articles {
    margin-bottom: var(--size-40);
  }

  & > .filters {
    margin-bottom: var(--size-56);
    z-index: -1;
  }
}

.filters {
  color: var(--color-blue-700);
  position: relative;
  z-index: 0;

  & > .artworkLayout {
    position: absolute;
    bottom: 0;
    left: -125px;
    z-index: -1;

    @media (--viewport-md) {
      bottom: -200px;
      left: var(--size-48);
    }
  }
}

.articles {
  & > .gridLayout {
    margin-bottom: var(--size-56);
  }
}
