.component {
  & > .headingLayout {
    margin-bottom: var(--size-12);
  }

  & > .notes {
    margin-top: var(--size-12);
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-18);

  @media (--viewport-md) {
    grid-template-columns: repeat(9, 1fr);
  }

  & > :nth-child(1),
  & > :nth-child(7) {
    @media (--viewport-md) {
      grid-column: span 4;
    }
  }

  & > :nth-child(2),
  & > :nth-child(6) {
    @media (--viewport-md) {
      grid-column: span 5;
    }
  }

  & > * {
    max-width: 100%;
    min-height: 180px;
    width: 100%;

    @media (--viewport-md) {
      min-height: 220px;
      grid-column: span 3;
    }
  }
}

.componentCard {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: min-content 1fr;
  background-color: var(--color-white);
  border-radius: var(--border-radius-8);
  color: var(--color-blue-700);
  font-family: var(--font-family-heading);
  padding: var(--size-24);

  & > .heading {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
  }

  & > .description {
    grid-column: 1 / 5;
    grid-row: 2 / 3;
  }

  &:nth-child(1),
  &:nth-child(7),
  &:nth-child(5) {
    background-color: var(--color-blue-300);
  }

  &:nth-child(2),
  &:nth-child(3) {
    background-color: var(--color-blue-600);
  }
}

.heading {
  font-size: var(--font-size-40-70);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-lg);
}

.type {
  font-size: var(--font-size-32-40);
}

.description {
  font-size: var(--font-size-18-22);
  line-height: var(--line-height-sm);
}

.notes {
  list-style-type: none;
  padding-left: 0;
  font-size: var(--font-size-12);
  line-height: var(--line-height-lg);
  color: var(--color-gray-700);

  @media (--viewport-md) {
    font-size: var(--font-size-14);
  }
}
