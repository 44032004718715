.page {
  background-color: var(--color-blue-300);
  padding-top: var(--size-112);
  color: var(--color-blue-700);

  & > .relatedArticlestitleLayout {
    margin-top: var(--size-74);

    @media (--viewport-md) {
      margin-top: var(--size-148);
      margin-bottom: var(--size-24);
    }
  }
}

.layout {
  display: grid;
  column-gap: var(--size-48);
  grid-template-areas:
    'button'
    'side'
    'content';

  @media (--viewport-md) {
    grid-template-columns: minmax(auto, 300px) auto;
    grid-template-areas:
      'button .'
      'side content';
  }

  & > .content {
    grid-area: content;
  }

  & > .sidebarLayout {
    grid-area: side;
    margin-bottom: var(--size-64);
  }

  & > .button {
    grid-area: button;
    margin-bottom: var(--size-20);
  }
}

.button {
  display: inline-block;

  & > .buttonLayout {
    display: inline-flex;
  }
}

.content {
  @media (--viewport-md) {
    padding-top: var(--size-24);
  }

  & > .bioTitleLayout {
    margin-bottom: var(--size-24);
  }

  & > .sideActivitiesTitleLayout {
    margin-block: var(--size-74) var(--size-24);
  }
}

.sideInner {
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-14);

  @media (--viewport-md) {
    position: sticky;
    top: 0;
    padding-top: var(--size-24);
  }

  & > .image {
    order: -1;

    @media (--viewport-md) {
      max-width: 250px;
      margin-inline: auto;
    }
  }

  & > .nameLayout {
    margin-block: var(--size-32) var(--size-24);
  }

  & > .socials {
    margin-top: var(--size-12);
  }

  & > .images {
    margin-top: var(--size-24);
  }
}

.profile {
  line-height: 2;
}

.image {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
}

.images {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-12);

  & > .link {
    width: 20%;
    height: 20%;
  }
}

.link {
  border-radius: var(--size-4);
  overflow: hidden;
}

.socials {
  & > .socialLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.socialLayout {
  & > * {
    width: 100%;
    height: 100%;
  }
}
