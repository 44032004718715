.page {
  color: var(--color-blue-700);
  background-color: var(--color-blue-600);
}

.faq {
  padding: var(--size-74) 0;
  background-color: var(--color-blue-300);
  position: relative;
  z-index: 0;
  overflow: hidden;

  @media (--viewport-md) {
    padding: var(--size-148) 0;
  }

  & > .artworkLayout {
    position: absolute;
    left: -125px;
    bottom: -125px;
    z-index: -1;
  }
}

.content {
  padding-top: var(--size-40);
  padding-bottom: var(--size-40);

  @media (--viewport-md) {
    padding-top: var(--size-112);
    padding-bottom: var(--size-112);
  }
}
