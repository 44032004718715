.component {
  display: flex;
  align-items: center;
  gap: var(--size-32);
  background-color: var(--color-white);
  padding: var(--size-10) var(--size-20);
  border-radius: var(--border-radius-pill);

  & > .input {
    width: 100%;
  }

  & > .searchButton {
    width: var(--size-24);
  }
}

.input {
  outline: none;
  color: var(--color-black);
}

.searchButton {
  color: var(--color-blue-700);
}
