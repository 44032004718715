.component {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-blue-700);
  padding: var(--container-padding);
  font-size: var(--font-size-16);
  gap: var(--size-16);

  @media (--viewport-md) {
    gap: var(--size-24);
    font-size: var(--font-size-18);
  }

  & > .link {
    height: var(--size-48);
    width: var(--size-48);
    flex-shrink: 0;

    @media (--viewport-md) {
      height: var(--size-32);
      width: var(--size-32);
    }
  }
}

.link {
  --scale-icon: 1;

  background-color: var(--color-blue-500);
  border-radius: 50%;
  transform: scale(var(--scale-icon));
  transition: transform var(--duration-200) var(--ease-in-out);
  color: var(--color-white);
  display: grid;
  place-items: center;

  & > .text {
    display: none;
  }

  & > .iconLayout {
    width: var(--size-24);
    height: var(--size-24);
  }

  &:hover,
  &:focus-visible {
    --scale-icon: 1.2;
  }
}

.pagination {
  display: flex;
  gap: var(--size-12);

  @media (--viewport-md) {
    gap: var(--size-20);
  }
}

.number {
  &.isActive {
    font-weight: var(--font-weight-semibold);
  }
}
