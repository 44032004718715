.page {
  background-color: var(--color-blue-300);

  & > .hero {
    margin-bottom: var(--size-48);
  }

  & > .grid {
    margin-bottom: var(--size-74);
  }
}
