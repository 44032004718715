.page {
  background-color: var(--color-blue-200);
  color: var(--color-blue-700);
  position: relative;

  & > .hero {
    margin-bottom: var(--size-48);
  }

  & > .summary {
    margin-bottom: var(--size-48);
  }

  & > .introduction {
    margin-bottom: var(--size-48);
  }

  & > .content {
    margin-bottom: var(--size-48);

    @media (--viewport-md) {
      margin-bottom: var(--size-74);
    }
  }

  & > .copyActionButton {
    margin-bottom: var(--size-18);
    margin-right: var(--size-18);
    bottom: var(--size-18);

    @media (--viewport-md) {
      bottom: var(--size-48);
      margin-right: var(--size-48);
    }
  }
}

.copyActionButton {
  position: sticky;
  display: flex;
  justify-content: flex-end;
}
