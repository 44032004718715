.component {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-32);

  & > .searchBarLayout {
    width: 100%;
  }
}
