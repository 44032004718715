.component {
  display: flex;
  flex-direction: column;
  gap: var(--size-32);

  & > .loaderLayout {
    margin: 2vmin auto 0;
  }
}

.resultsHeader {
  text-align: center;
}

.results {
  display: flex;
  flex-direction: column;
  gap: var(--size-24);
}
