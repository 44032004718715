.component {
  display: flex;
  align-items: center;
  gap: var(--size-8);

  & > .coverImage {
    width: var(--size-64);
  }
}

.coverImage {
  border-radius: 50%;
  overflow: hidden;
}

.title {
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-bold);
}
