.layout {
  display: flex;
  justify-content: center;
  gap: var(--size-12);
  flex-direction: column;

  @media (--viewport-lg) {
    flex-direction: row;
    align-items: center;
  }
}

.filters {
  display: flex;
  gap: var(--size-12);
  flex-wrap: wrap;

  @media (--viewport-md) {
    flex-wrap: nowrap;
  }

  & > * {
    flex-grow: 1;

    &:nth-child(1) {
      width: 100%;
    }

    @media (--viewport-md) {
      min-width: 170px;
    }
  }
}

.text {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-18-22);
}
