.component {
  position: relative;
  z-index: 0;

  &::before {
    display: block;
    content: '';
    height: 100%;
    padding-top: calc(100% / (16 / 9));
  }

  & > .player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
