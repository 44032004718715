.component {
  display: none;

  @media (--viewport-md) {
    display: flex;
    gap: var(--size-40);
    align-items: center;
  }
}

.componentItemsDesktop {
  position: relative;
  z-index: 0;
  font-size: var(--font-size-14);

  & > .dropdownDesktop {
    width: 210px;
    position: absolute;
    top: var(--size-24);
    z-index: -1;
    display: none;
  }

  &:hover {
    & > .dropdownDesktop {
      display: flex;
    }
  }
}

.dropdownDesktop {
  padding: var(--size-32) var(--size-18) var(--size-18);
  background-color: var(--color-blue-700);
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-12);
  }
}

.dropdownLinkDesktop {
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: transform var(--duration-200) ease;
  transform: translateX(calc(var(--size-20) * -1));
  text-decoration: none;

  & > :first-child {
    margin-right: var(--size-8);
  }

  &:hover {
    transform: translateX(0);

    & > .iconLayout {
      transform: translateX(0);
    }
  }
}

.iconLayout {
  display: flex;
  align-items: center;
  transform: translateX(calc(var(--size-20) * -1));
  transition: transform var(--duration-200) ease;
}

.componentLanguageSwitchDesktop {
  border-left: 1px solid var(--color-blue-300);
  padding-left: var(--size-32);
}

.button {
  display: flex;
  gap: var(--size-8);
  cursor: pointer;
  align-items: center;
}

.component_rootSearchOverlay {
  background-color: var(--color-black-900--30);
  transition: opacity var(--duration-200) var(--ease-in-out);
  opacity: 0;
  position: fixed;
  inset: 0;
  pointer-events: none;

  &.open {
    opacity: 0.5;
    pointer-events: auto;
  }
}

.componentSearch {
  position: relative;
  display: flex;

  & > .searchContainer {
    position: absolute;
    top: calc(100% + 37px);
    right: 0;
    width: 409px;
  }
}

.searchContainer {
  transform: translateY(-20px);
  opacity: 0;
  pointer-events: none;
  transition: var(--ease-in-out) var(--duration-sm);
  transition-property: transform, opacity;
  will-change: transform, opacity;

  &.open {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}
