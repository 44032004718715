.component {
  background-color: var(--color-blue-300);
  padding: 1px 0;
  min-height: calc(100vh - 11.5rem) !important;

  @media (--viewport-md) {
    min-height: calc(100vh - 12.6rem) !important;
  }

  & > .heading {
    margin-top: var(--size-32);
  }

  & > .window {
    position: sticky;
    top: 11.5rem;
    height: calc(100vh - 11.5rem);

    @media (--viewport-md) {
      top: 12.6rem;
      height: calc(100vh - 12.6rem);
    }
  }
}

.window {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  & > * {
    width: 100%;
  }
}

.layout {
  & > .headingLayout {
    margin-bottom: var(--size-32);
  }
}

.itemContainer {
  display: flex;
}

.items {
  display: flex;
  gap: var(--size-40);

  @media (--viewport-md) {
    gap: var(--size-74);
  }

  & > * {
    width: 32rem;
    max-width: calc(95vw - 2 * var(--24));
    flex-shrink: 0;
  }

  & > :nth-child(even) {
    @media (--viewport-height-md) {
      margin-top: var(--size-layout-32);
    }
  }
}

.layoutYear {
  & > .subtitle {
    margin: var(--size-8) 0 var(--size-12) 0;
  }
}

.subtitle {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-lg);
  font-size: var(--font-size-18-22);
}

.description {
  font-size: var(--font-size-14-16);
}
