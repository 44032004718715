.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-40);
}

.textContainer {
  display: flex;
  flex-direction: column;
  row-gap: var(--size-20);

  & > * {
    min-width: 0;
  }

  & > .buttonLayout {
    margin-top: var(--size-12);
    width: max-content;
  }

  & > .headingLayout {
    margin-left: var(--size-20);

    @media (--viewport-md) {
      margin-left: var(--size-32);
    }
  }
}
