.component {
  --scale-icon: 1;

  background-color: var(--color-blue-300);
  padding: var(--size-74) 0;

  @media (--viewport-md) {
    padding: var(--size-148) 0;
  }
}

.layout {
  & > .headingLayout {
    margin-bottom: var(--size-24);
  }
}

.people {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-24);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.componentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--color-blue-200);
  border-radius: var(--border-radius-20);
  padding: var(--size-40) var(--size-24);
  color: var(--color-blue-700);
  line-height: var(--line-height-md);

  & > .coverImage {
    max-width: 175px;
    margin-bottom: var(--size-24);
  }
}

.coverImage {
  border-radius: 50%;
  overflow: hidden;
}

.content {
  & > .role {
    margin-bottom: var(--size-18);
  }
}

.name {
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-24-28);
  line-height: 1;
}

.role {
  font-size: var(--font-size-18);
}

.email,
.telephone {
  font-size: var(--font-size-14);
}

.iconWrapper {
  & > .icon {
    height: var(--size-32);
    width: var(--size-32);
    align-self: end;
    justify-self: end;
  }
}

.icon {
  background-color: var(--color-blue-500);
  border-radius: 50%;
  transform: scale(var(--scale-icon));
  transition: transform var(--duration-200) var(--ease-in-out);
  color: var(--color-white);
  display: grid;
  place-items: center;

  &:hover {
    --scale-icon: 1.2;
    --translate-y: -8px;
  }
}
