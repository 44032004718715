.componentBase {
  display: flex;
  padding: var(--size-18) var(--size-24);
  font-size: var(--font-size-14-16);
  background: var(--color-blue-500);
  border-radius: var(--border-radius-8);
  color: var(--color-white);
  line-height: 1;
  gap: var(--size-10);

  & > .iconLayout {
    flex-shrink: 0;
    width: var(--size-12);

    @media (--viewport-md) {
      width: 14px;
    }
  }
}
