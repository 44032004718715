.component {
  color: var(--color-blue-600);

  & > .title {
    margin-bottom: var(--size-24);
  }

  & > .buttonLayout {
    margin: 0 auto;
    margin-top: var(--size-32);
    width: fit-content;
  }
}

.title {
  font-family: var(--font-family-heading);
  color: var(--color-blue-600);
  font-size: var(--font-size-40-82);
  font-weight: var(--font-weight-black);
  line-height: var(--line-height-xl);
}

.grid {
  --auto-grid-min-size: 280px;
  --auto-grid-max-size: auto;

  @media (--viewport-md) {
    --auto-grid-max-size: 320px;
  }

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--auto-grid-min-size), var(--auto-grid-max-size)));
  gap: var(--size-24);
  justify-content: center;
}
