.componentLg {
  font-size: var(--font-size-22-26);
}

.componentMd {
  font-size: var(--font-size-18-22);
}

.componentSm {
  font-size: var(--font-size-16-18);
}

.componentBase {
  font-family: var(--font-family-base);
  line-height: var(--line-height-md);
}
