.layout {
  display: grid;
  column-gap: var(--size-24);
  row-gap: var(--size-32);

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewport-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}
