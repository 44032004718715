.component {
  background-color: var(--color-blue-200);
  color: var(--color-blue-700);
  position: relative;
  z-index: 0;

  & > .subnavigationLayout {
    z-index: 1;
    margin: var(--size-32) 0 var(--size-18) 0;

    @media (--viewport-md) {
      margin: var(--size-40) 0;
    }
  }
}
