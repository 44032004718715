.component {
  --link-icon-size: 1;
  --background-color: var(--color-white);

  border-radius: var(--border-radius-8);
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--viewport-lg) {
    grid-template-columns: 2fr 1fr;
  }

  & > .imageLayout {
    height: 100%;
  }

  & > .content {
    position: static;
  }
}

.content {
  background-color: var(--background-color);
  padding: var(--size-24);
  color: var(--color-blue-700);
  position: relative;
  transition: background-color var(--duration-200) var(--ease-in-out);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:hover {
    --link-icon-size: 1.1;
    --background-color: var(--color-blue-200);
  }

  @media (--viewport-md) {
    padding: var(--size-32);
  }

  & > .link {
    position: static;
  }

  & > .chipLayout {
    margin-bottom: var(--size-32);
  }

  & > .description {
    margin-top: var(--size-24);
    margin-bottom: var(--size-4);
  }

  & > .linkIcon {
    align-self: flex-end;
    margin-top: auto;
  }
}

.description {
  font-size: var(--font-size-14-16);
  color: var(--color-gray-900);
  line-height: var(--line-height-text);
}

.link {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }
}

.linkIcon {
  background-color: var(--color-blue-700);
  color: var(--color-white);
  transition: transform var(--duration-200) var(--ease-in-out);
  transform: scale(var(--link-icon-size));
  border-radius: 50%;
  height: var(--size-32) !important;
  width: var(--size-32) !important;
  display: grid;
  place-items: center;
  pointer-events: none;
}
