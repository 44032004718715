.component {
  position: relative;
  z-index: 0;
  padding-bottom: var(--size-40);

  @media (--viewport-md) {
    padding-bottom: 0;
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 50%;

    @media (--viewport-md) {
      background-color: var(--color-blue-700);
    }
  }

  & > .layout {
    margin-top: var(--size-24);
  }
}

.layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-24);
  padding-top: var(--size-12);

  @media (--viewport-md) {
    grid-template-columns: 1fr 1fr;
  }

  & > * {
    margin-bottom: var(--size-18);

    @media (--viewport-md) {
      margin-bottom: 75px;
    }
  }
}
