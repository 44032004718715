.component {
  background-color: var(--color-blue-700);
  color: var(--color-white);
  font-size: var(--font-size-14);
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--size-24) 0;

  @media (--viewport-md) {
    padding: var(--size-48) 0;
  }

  & > .socials {
    margin-bottom: var(--size-20);

    @media (--viewport-md) {
      margin-bottom: var(--size-40);
    }
  }

  & > .logoLayout {
    width: 100%;
    max-width: 317px;
    margin-bottom: var(--size-32);
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  gap: var(--size-16);
}

.socials {
  display: flex;
  gap: var(--size-12);
}

.socialLinkLayout {
  & > .iconLayout {
    width: var(--size-20);
    height: var(--size-20);
  }
}

.iconLayout {
  & > * {
    width: 100%;
    height: 100%;
  }
}
