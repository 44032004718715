.page {
  background: var(--color-blue-700);
  padding-top: var(--size-112);

  @media (--viewport-md) {
    padding-top: calc(var(--size-148) + var(--size-header-height-md));
  }

  & > .hero {
    margin-bottom: var(--size-40);
  }

  & > .filters {
    margin-bottom: var(--size-40);
  }

  & > .grid {
    margin-bottom: var(--size-96);
  }
}

.filters {
  color: var(--color-white);
}

.contactCta {
  background-color: var(--color-blue-300);
}
