.component {
  background-color: var(--color-blue-700);
  color: var(--color-white);
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--size-header-height-sm);
  padding: var(--container-padding);

  @media (--viewport-md) {
    height: var(--size-header-height-md);
    padding: var(--container-padding-md);
  }

  & > .logoLayout {
    flex-shrink: 0;
    width: var(--size-74);

    @media (--viewport-md) {
      width: var(--size-96);
    }
  }
}
