.component {
  font-size: var(--font-size-16);
  background-color: var(--color-blue-700);
  color: var(--color-white);
  padding: var(--size-20) var(--size-20) var(--size-12) var(--size-20);
  border-radius: 0 var(--size-20) var(--size-20);

  & > .text {
    margin-bottom: var(--size-8);
  }
}
