.component {
  position: relative;

  & > .dotsLayout {
    position: absolute;
    right: unset;
    bottom: calc(var(--size-40) * -1);
    width: 100%;

    @media (--viewport-md) {
      width: unset;
      right: calc(var(--size-32) * -1);
      top: 50%;
      bottom: 50%;
    }
  }
}

.container {
  position: relative;
  transition: opacity var(--duration-150);
  opacity: 0;

  &.isVisible {
    opacity: 1;
  }
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1920px !important;
  perspective: 1000px;
}

.componentDots {
  --dot-size: 10px;

  gap: 1.25rem;
  display: flex;
  justify-content: center;

  @media (--viewport-md) {
    display: grid;
    transform: translateY(-50%);
    grid-template-rows: repeat(auto-fill, min(0, 1fr));
  }

  & > .dotLayout {
    width: var(--dot-size);
    height: var(--dot-size);
  }
}

.componentDot {
  border-radius: 50%;
  outline-style: solid;
  outline-offset: 3px;
  outline-width: 2px;
  position: relative;

  & > .dotInnerElement {
    position: absolute;
  }
}

.dotInnerElement {
  position: relative;
  inset: 0;

  & > * {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    inset: 0;
  }
}
