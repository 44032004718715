.page {
  padding-top: var(--size-56);
  padding-bottom: var(--size-56);

  @media (--viewport-md) {
    padding-top: var(--size-74);
    padding-bottom: var(--size-74);
  }
}

.layout {
  & > :not(:first-child) {
    margin-top: var(--size-24);
  }

  & ul,
  & ol {
    padding-left: var(--size-24);
    list-style-position: outside;

    @media (--viewport-md) {
      padding-left: var(--size-40);
    }

    & > li {
      @media (--viewport-md) {
        /* stylelint-disable kaliber/layout-related-properties */
        /* reason: spacing can not be set with margin, it's value is depended on the context. */
        padding-left: var(--size-18);
        /* stylelint-enable kaliber/layout-related-properties */
      }
    }
  }

  & ol {
    list-style-type: decimal;

    & > li {
      /* stylelint-disable kaliber/layout-related-properties */
      /* reason: spacing can not be set with margin, it's value is depended on the .listOrdered context. */
      padding-left: var(--size-8);
      /* stylelint-enable kaliber/layout-related-properties */
    }
  }

  & ul {
    list-style-type: disc;
  }

  /* stylelint-disable kaliber/selector-policy */
  /* reason: dealing with WYSIWYG content */
  & p + ol,
  & p + ul,
  & ol + ol,
  & ol + ul,
  & ul + ol,
  & ul + ul,
  & h2 + h4,
  & h2 + *,
  & h4 + * {
    margin-top: var(--size-8);
  }

  & p + h2,
  & p + h4,
  & ol + h2,
  & ol + h4,
  & ul + h2,
  & ul + h4 {
    margin-top: var(--size-24);
  }
  /* stylelint-enable kaliber/selector-policy */
}
