.componentBase {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}

.componentBack {
  font-size: var(--font-size-16);
  display: flex;

  & > .iconLayout {
    margin-right: 14px;
    width: var(--size-24);
    height: var(--size-24);
  }
}

.componentUnderlined {
  text-decoration: underline;
}

.componentIcon {
  font-size: var(--font-size-16);
  display: flex;
  align-items: center;
  gap: var(--size-8);
}
