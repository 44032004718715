.page {
  background-color: var(--color-blue-300);

  & > .hero {
    margin-bottom: var(--size-56);
  }

  & > .articles {
    margin-bottom: var(--size-40);
  }

  & > .filters {
    margin-bottom: var(--size-56);
  }
}

.filters {
  color: var(--color-blue-700);
}

.articles {
  & > .gridLayout {
    margin-bottom: var(--size-56);
  }
}
