.component {
  padding: var(--size-96) 0;
}

.layout {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-74);

  @media (--viewport-lg) {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-blue-700);

  & > .headingLayout:last-of-type {
    margin-bottom: var(--size-12);
  }

  & > .textLayout {
    margin-bottom: var(--size-18);
  }
}

.buttons {
  display: flex;
  gap: var(--size-12);
}

.media {
  position: relative;

  & > .coverImage {
    margin: 0 auto;
    max-width: 350px;

    @media (--viewport-md) {
      max-width: 450px;
    }
  }

  & > .bubbleWrapper {
    @media (--viewport-md) {
      max-width: 280px;
      position: absolute;
      right: calc(-1 * var(--size-24));
      top: 50%;
    }
  }
}

.bubbleWrapper {
  display: none;
}

.coverImage {
  background-color: var(--color-blue-600);
  border-radius: 50%;
  position: relative;
  overflow: hidden;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 100%;
  }

  & > .imageLayout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
