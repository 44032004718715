.component {
  --background: var(--color-blue-700);

  color: var(--color-white);
  background: var(--background);
  padding: var(--size-16);
  border-radius: 999px;
  transition: background-color var(--duration-150) var(--ease-in-out);

  &.isAnimating {
    --background: var(--color-blue-500);
  }

  &:hover {
    --background: var(--color-blue-500);
  }
}

.wrapperElement {
  display: grid;
  grid-template-areas: 'icon';

  &.withLabel {
    grid-template-areas: 'icon label';
    gap: var(--size-16);
  }

  & > .iconWrapperElement {
    grid-area: icon;
  }

  & > .labelElement {
    grid-area: label;
    margin-right: var(--size-8);
  }
}

.iconWrapperElement {
  aspect-ratio: 1 / 1;
}

.labelElement {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-size: var(--font-size-18);
}
