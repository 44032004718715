.page {
  position: relative;
  z-index: 0;

  & > .filter {
    z-index: 1;
    margin-top: var(--size-32);

    @media (--viewport-md) {
      margin-top: var(--size-56);
    }
  }
}

.hero {
  color: var(--color-blue-700);
}

.filter {
  display: flex;
  justify-content: center;
}

.faq {
  display: grid;
  gap: var(--size-32);

  @media (--viewport-md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.componentCategoryBlock {
  padding-top: var(--size-56);
  padding-bottom: var(--size-56);

  @media (--viewport-md) {
    padding-top: var(--size-74);
    padding-bottom: var(--size-74);
  }
}
