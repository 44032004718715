.componentBase {
  & > .header {
    margin-bottom: var(--size-12);
  }
}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: flex-start;
  color: var(--color-blue-700);
  font-size: var(--font-size-16);

  @media (--viewport-md) {
    align-items: flex-end;
    flex-direction: row;
  }
}
