.component {
  display: flex;
  align-items: center;

  @media (--viewport-md) {
    display: none;
  }
}

._rootDrawer {
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  overflow: hidden;

  @media (--viewport-md) {
    max-width: 500px;
  }

  & > .drawerLayout {
    height: 100%;
  }
}

.componentDrawer {
  background-color: var(--color-blue-700);
  padding: var(--container-padding);

  & > .drawerHeader {
    height: var(--size-56);
    margin-bottom: var(--size-24);
  }

  & > .searchFormLayout {
    margin-bottom: var(--size-24);
  }
}

.drawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .logoLayout {
    flex-shrink: 0;
    width: var(--size-74);

    @media (--viewport-md) {
      width: var(--size-96);
    }
  }
}

.drawerItems {
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
  position: relative;
  font-size: var(--font-size-24);
  font-weight: var(--font-weight-semibold);

  & > .drawerOverlay {
    position: absolute;
    inset: 0;
  }
}

.drawerOverlay {
  background-color: var(--color-blue-700);
  display: flex;
  flex-direction: column;
  gap: var(--size-16);
}

.componentLanguageSwitchMobile {
  border-top: 1px solid var(--color-blue-300);
  padding-top: var(--size-16);
}

.button {
  display: flex;
  gap: var(--size-8);
  cursor: pointer;
  align-items: center;
}

.backButton {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-regular);
}
