.page {
  background-color: var(--color-blue-300);
}

.search {
  padding-block: var(--size-74);
  display: flex;
  flex-direction: column;
  gap: var(--size-48);

  @media (--viewport-md) {
    padding-block: var(--size-148);
  }

  & > .headingLayout {
    justify-self: center;
  }
}
