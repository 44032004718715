.component {
  display: flex;
  font-size: var(--font-size-16);
  position: relative;
  background-color: var(--color-white);
  border-radius: var(--border-radius-pill);
  color: var(--color-blue-700);

  & > .label {
    display: none;
  }

  & > .select {
    flex-grow: 1;
  }

  & > .chevronDown {
    position: absolute;
    right: var(--size-18);
    height: 100%;
  }
}

.select {
  padding: var(--size-8) var(--size-24);
  padding-right: var(--size-48);
  cursor: pointer;
}

.chevronDown {
  display: flex;
  align-items: center;
  pointer-events: none;
}
