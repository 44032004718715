.componentBase {
  overflow: auto; /* Disable margin collapse */
}

.componentMainContent {
  & > .heading {
    margin-bottom: var(--size-16);
  }

  & > .subheading {
    margin-bottom: var(--size-10);
  }

  & > .paragraph,
  & > .list {
    margin-bottom: var(--size-40);
  }

  & > :last-child {
    margin-bottom: 0;
  }
}

.heading {
  font-size: var(--font-size-28-32);
  line-height: var(--line-height-lg);
}

.subheading {
  font-size: var(--font-size-18-22);
  line-height: var(--line-height-lg);
}

.list {
  padding-left: 1em;
  list-style-position: inside;
}

.listOrdered {
  list-style-type: decimal;
}

.listUnordered {
  list-style-type: disc;
}

.strong {
  font-weight: var(--font-weight-medium);
}
