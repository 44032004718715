
.componentMd {
  & > .artworkImage {
    width: 350px;

    @media (--viewport-md) {
      width: 400px;
    }
  }
}

.componentLg {
  & > .artworkImage {
    width: 350px;

    @media (--viewport-md) {
      width: 540px;
    }
  }
}
