.component {
  background: var(--color-blue-700);
  position: relative;
  padding: var(--size-74) 0;

  & > .titleContainer {
    @media (--viewport-md) {
      top: 0;
      height: 100lvh;
    }
  }

  & > .content {
    @media (--viewport-md) {
      height: 100lvh;
    }
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  padding: var(--container-padding);
  overflow: hidden;

  @media (--viewport-md) {
    justify-content: center;
    position: sticky;
    padding: 0;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--container-padding);

  @media (--viewport-md) {
    padding: var(--container-padding-md);
  }

  & > .grid {
    width: 100%;
    max-width: var(--container-max-width-xxl);
  }

  & > .buttonWrapper {
    margin-top: var(--size-32);
  }
}

.title {
  color: var(--color-blue-600);
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-black);
  user-select: none;
  font-size: var(--font-size-40-82);

  @media (--viewport-md) {
    font-size: var(--font-size-100-400);
    text-align: center;
  }
}

.grid {
  --auto-grid-min-size: 280px;

  display: grid;
  gap: var(--size-24);
  justify-content: center;

  @media (--viewport-md) {
    grid-template-columns: repeat(2, minmax(var(--auto-grid-min-size), auto));
  }

  @media (--viewport-lg) {
    --auto-grid-min-size: 100px;

    grid-template-columns: repeat(4, minmax(var(--auto-grid-min-size), auto));
  }
}

.cardWrapper {
  & > * {
    height: 100%;
  }
}
