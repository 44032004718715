.componentBase {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-40-82);
  line-height: var(--line-height-xl);
  color: var(--color-blue-700);
}

.component {
  font-size: var(--font-size-40-82);
}

.componentLg {
  font-size: var(--font-size-40-52);
}

.strongElement {
  font-weight: var(--font-weight-black);
}
